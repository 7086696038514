import { Script } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";


function Seo(props) {
  const { title, description, url, addClarityScript=false } = props;


  return (
    <Helmet htmlAttributes={{ lang: "en" }}>
      <meta charSet="utf-8" />
      <meta
        name="facebook-domain-verification"
        content="61fwhgdwdun5wk0mcxhhsi8vl0b6xr"
      />
      {url && <meta property="og:url" content={url} />}
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}

      {props.noIndex ? (
        <meta name="robots" content="noindex, nofollow" />
      ) : (
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
      )}
      <meta property="og:locale" content="en_US" />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta name="robots" content="max-snippet:-1" />
      <meta
        property="og:image"
        content={
          props.image ?? "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1osoflx.png"

        }
      />
      <link rel="canonical" href={url} />

      <link
        rel="shortcut icon"
        type="image/x-icon/png"
        href="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1oskwgy.ico"

      />

      {/* <link
        rel="shortcut icon"
        type="image/png"
        href="https://kfwebassets.s3.ap-south-1.amazonaws.com/Kraftshala-Social.png"
      />

      <link
        rel="apple-touch-icon"
        href="https://kfwebassets.s3.ap-south-1.amazonaws.com/Kraftshala-Social.png"
      />

      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="https://kfwebassets.s3.ap-south-1.amazonaws.com/Kraftshala-Social.png"
      /> */}

      {props.homeSnipptJSON && (
        <script defer type="application/ld+json">{props.homeSnipptJSON}</script>
      )}

      {props.courseSnipptJSON && (
        <script defer type="application/ld+json">{props.courseSnipptJSON}</script>
      )}

      {props.faqSnipptJSON && (
        <script defer type="application/ld+json">{props.faqSnipptJSON}</script>
      )}

      {props.videoSnipptJSON && (
        <script defer type="application/ld+json">{props.videoSnipptJSON}</script>
      )}

      {props.eventSnipptJSON && (
        <script defer type="application/ld+json">{props.eventSnipptJSON}</script>
      )}

      <Script type="module">
        {`
          (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
            //j.async = true;
            j.defer = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", "GTM-TK295XB");
          `}
      </Script>
      <script type="module">
        {`
          !function(f, b, e, v, n, t, s) {
            if (f.fbq)
                return;
            n = f.fbq = function() {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            }
            ;
            if (!f._fbq)
                f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            //t.async = !0;
            t.defer = true;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
          }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '254822124949497');
          // Insert your pixel ID here.
          fbq('track', 'PageView');
        `}
      </script>
      {
        (addClarityScript) &&
        <script type="module"> 
          {`(function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){
              (c[a].q=c[a].q||[]).push(arguments);
            };

            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); 
          })(window, document, "clarity", "script", "pg9rpkxx9s")`}
        </script>
      }
      <script src="https://cdn-in.pagesense.io/js/kraftshala/fa0365ff522346479b89ade2528fe68b.js"></script>
    </Helmet>
  );
}

export default Seo;
